@font-face {
  font-family: "orbit";
  src: url("./fonts/orbit.eot");
  src: url("./fonts/orbit.eot?#iefix") format("embedded-opentype"),
    url("./fonts/orbit.woff") format("woff"),
    url("./fonts/orbit.ttf") format("truetype"),
    url("./fonts/orbit.svg#orbit") format("svg");
  font-weight: normal;
  font-style: normal;
}
[class*="icon-"]:before {
  display: inline-block;
  font-family: "orbit";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-logo-orbit-black:before {
  content: "\0041";
}
.icon-logo-orbit-color:before {
  content: "\0042";
}
.icon-logo-orbit-grayscale:before {
  content: "\0043";
}
